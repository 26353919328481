.table {

    .tableCella {
        font-weight: 600;
        color: #515157;
    }


    .timeLeft {
        padding: 5px;
        border-radius: 5px;

        &.Overdue {
            color: red;
            background-color: rgba(255, 0, 0, 0.151);
        }

        &.Short {
            color: rgb(220, 142, 87);
            background-color: rgba(255, 149, 0, 0.162);
        }

        &.Long {
            color: rgb(0, 238, 255);
            background-color: #00f7ff27;
        }
    }

    .status {
        padding: 5px;
        border-radius: 5px;

        &.Ready,&.Completed {
            color: green;
            background-color: rgba(0, 128, 0, 0.151);
        }

        &.Late {
            color: red;
            background-color: rgba(255, 0, 0, 0.151);
        }

        &.Cancelled {
            color: rgb(210,91,17);
            background-color: rgba(200, 52, 11, 0.151);
        }

        &.Uncollected {
            color: rgb(224, 156, 114);
            background-color: rgba(133, 70, 7, 0.151);
        }

        &.Pick,&.ShortPick,&.Picking,&.Picked {
            color: rgb(218, 103, 32);
            background-color: rgba(218, 165, 32, 0.151);
        }

        &.New {
            color: #515157;
            background-color: rgba(81, 81, 87, 0.151);
        }

        &.Pack, &.Packing {
            color: rgb(0, 32, 194);
            background-color: rgba(0, 32, 194, 0.151);
        }

        &.Pending {
            color: red;
            background-color: rgba(255, 0, 0, 0.151);
        }
    }

    .popup {
        border-radius: 20px;
        height: 400px; //Ändra denna sedan till Auto
        width: 25%;
        background-color: rgb(235, 26, 26);
        position: absolute;
        top: 25%;
        right: 38%;
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        ;
    }
}
