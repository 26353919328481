.header {
    height: 50px;
    border-bottom: 0.5px solid rgb(231, 228, 228);
    display: flex;
    align-items: center;

    .wrapper {
        width: 100%;
        padding: 20PX;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .headerTitle {
            display: flex;
            font-size: 10px;
            font-weight: bold;
            color: #515157;
        }
    }
}
