.main {
    position: fixed;
    z-index: 1;
    background-color: rgba(66, 66, 66, 0.075);
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
}

.scrollable-div {
    background-color: #f1f1f1;
    height: 150px;
    overflow: auto;
    margin: 20px;
    text-align: justify;
    padding: 20px;
  }

.table .popup {
    border-radius: 20px;
    height: 400px; //Ändra denna sedan till Auto
    width: 40%;
    background-color: white;
    position: absolute;
    top: 10%;
    right: 25%;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);

    .cardheader {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        border-bottom: 0.5px solid rgb(231, 228, 228);

        .cardinfo {
            padding-top: 30px;
            padding-left: 20px;
            line-height: 30px;

            .cardinfo p {
                font-size: 12px;
            }

            .title {
                font-size: 18px;
                color: #cd2026;
            }
        }

        .closebtn {
            padding-top: 10px;
            padding-right: 15px;
            cursor: pointer;
        }
    }
}
