.flex-data {
  display: flex;
  padding: 2rem;
  gap: 2rem;
  width: 35rem;
}

.flex-data-history {
  display: flex;
  padding: 20px;
  gap: 20px;
  width: auto;
}
.overview-title {
  display: flex;
  justify-content: left;
  /* width: 100%; */
  flex-direction: column;
}
// .float-container {
//   border: 3px solid #fff;
//   padding: 20px;
//   //display: flex;
//   display: contents;
// }

.widget-wrap-overview {
  display: flex;
  /* justify-content: left; */
  gap: 0px;
  padding: 15px;
}


.data-container {
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  display: flex;
  gap: 10px;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  /* display: inline-flex; */
  height: 180px; 
  vertical-align: middle;
  /* width:50%; */
  /* gap: 10px; */
}
/* @media (max-width:1280px)
    {
        .data-container{
            min-height: 55%;
        }
    } */
.data-overviewcontainer-actionbox {
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  display: inline-flex;
  height: 180px;
  vertical-align: middle;
  display: flex;
  flex-direction: column
  
  /* font-size: 1.2rem;
  flex-direction: column;
  justify-content: space-evenly;
  width: 25%;
  flex-direction: column; */
}

