.sidebar {
    flex: 1;
    border-right: 0.5px solid #DADADB;
    min-height: 100vh;
    background-color: white;



    .top {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;


        .logo img {
            font-size: 20px;
            font-weight: bold;
            color: purple;
            width: 80px;
            height: 80px;
        }
    }

    hr {
        height: 0;
        border: 0.5px solid #DADADB;
        width: 80px;
        margin-left: 40px;
    }

    .center {
        padding-left: 10px;

        ul {
            list-style: none;
            margin: 0;
            padding: 20px;

            li {
                display: flex;
                align-items: center;
                padding: 10px;
                cursor: pointer;

                &:hover {
                    background-color: #cd20269d;
                    border-radius: 20px;
                }

                .icon {
                    font-size: 20px;
                    color: #515157;
                }

                span {
                    font-size: 13px;
                    font-weight: 600;
                    color: #515157;
                    margin-left: 10px;
                }
            }
        }
    }

    .bottom {
        display: flex;
        align-items: center;
        margin: 10px;


        .colorOption {
            width: 20px;
            height: 20px;
            border-radius: 5px;
            border: 1px solid #515157;
            cursor: pointer;
            margin: 5px;

            &:nth-child(1) {
                background-color: whitesmoke;
            }

            &:nth-child(2) {
                background-color: #515157;
            }
        }
    }
}
