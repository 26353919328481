.productinfo {
    display: flex;
    font-size: 12px;
    font-weight: 700;
    border-bottom: 0.5px solid rgb(231, 228, 228);

    .productimg {
        padding-left: 20px;
        padding-top: 5px;
    }

    .productdetail {
        padding-left: 15px;
        padding-top: 5px;
        width: 170px;
    }

    .shortPick {
        margin: auto;
        float: right;
    }
}
