.widget-card {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    flex: 1;
    padding: 15px;
    -webkit-box-shadow: 2px 4px 10px 1px rbga(0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    border-radius: 10px;    

    .title {
        display: flex;
        font-size: 1rem;
        font-weight: 700;
        color: #515157;
        border-bottom: 2px solid #A6A6A6;
        align-items: center;
    }

  /*   @media (max-width:1280px)
    {
        .title{
            min-height: 55%;
        }
    } */

    .amount {
        display: flex;
        align-items: center;
        font-size: 45px;
        font-weight: 800;
    }

    .percentage{
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 600;
        color: blue;
    }
}

.normalHeight {
    height: 100px;
}

.extendendHeight {
    height: 120px;
    //color: cornflowerblue;
}
