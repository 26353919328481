.home {
    display: flex;

    .homeContainer {
        flex: 5;
        .widget-wrap {
                display: flex;
                /* justify-content: left; */
                gap: 0px;
                padding: 15px;
                height: 11rem;
        }

        .widget {
            display: flex;
            padding: 10px;
            gap: 10px;
            width: 82%;
        }
        .data-container-actionbox {
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 10px;
            margin: 10px;
            border-radius: 5px;
            display: inline-block;
            vertical-align: middle;
            width: 18%
          }

        .listContainer {
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 20px;
            margin: 20px;
            border-radius: 10px;
            .listTitle {
                font-weight: 900;
                color: #515157;
                margin-bottom: 10px;
            }
        }
    }
}
