.orderhead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #515157;
    height: 20px;
    padding: 10px;

    .orderTitle {
        font-weight: 700;
        color: #515157;
        margin-left: 10px;
        height: 20%;
    }   

    .dayFilltering {
        margin-left: 6em;
        //align-items: center;
        .active {
            background-color: rgba(229, 228, 226);
        }
    }

    .autocomplete {
        width: 200px;
        padding-right: 10px;
        padding-top: 5px;
    }

    .MuiButton-root{
        color: #515157;
        font-weight: 700;    
    }

    .MuiButtonGroup-root .MuiButtonGroup-grouped:not(:last-of-type){
        border-color: #515157;
    }

    .MuiOutlinedInput-root{
        height: 30px;
        width: 200px;
        border-radius: 10px;
    }

    .MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
        margin-bottom: 20px;
    }
    .MuiInputLabel-root {
         font-size: 0.9rem;
         bottom: 20px;
         top: -10px;
     }

     .MuiOutlinedInput-input {
         height: 0.75em;
     }

    .MuiOutlinedInput-root {
         padding: 1px;
         border-radius: 10px;
     }

     .MuiAutocomplete-root .MuiOutlinedInput-root{
         padding: 3px;
     }
}
