.action-box {
    //-webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0.47);
    //box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    //padding: 20px;
    //margin: 20px;
    /* font-size: 1.2rem; */
    // height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 85%;

    div:first-child {
        font-weight: 600;
        border-bottom: 1px solid gray;
    }

    .div {
        font-family: fangsong;
    }
}

.pointer {
    cursor: pointer;
}

.modal {
    position: fixed;
    z-index: 1;
    background-color: rgba(66, 66, 66, 0.075);
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
}

.scrollable-div {
    background-color: #f1f1f1;
    height: 150px;
    overflow: auto;
    margin: 20px;
    text-align: justify;
    padding: 20px;
}

.popup {
    border-radius: 20px;
    height: 350px; //Ändra denna sedan till Auto
    width: 53%;
    background-color: white;
    position: absolute;
    top: 10%;
    right: 20%;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
}

.greyout {
    color: lightgray;
}

.loader {
    border: 1px solid #c3cdd4;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    display: inline-block;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.modal-header {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    border-bottom: 0.5px solid rgb(231, 228, 228);
}

.modal-header-title {
    margin: auto 0;
}

.close-btn {
    //padding-top: 10px;
    //padding-right: 15px;
    cursor: pointer;
}