.aggregatedDataFiltering {
    .active {
        background-color: rgba(229, 228, 226);
    }
    width: 530px;
    margin: auto;
    padding-left: 65px;
    padding-bottom: 10px;

    .MuiButton-root{
        color: #515157;
        font-weight: 700;  
        padding: 5px;  
    }
}

.titleFilter {
        width: 150px; 
        padding: 5px;
        //background: #5F85DB;
        background: #B6B9BE;
        color: #fff;
        text-align: center;
        font-weight: bold;
        font-family: Tahoma;
}